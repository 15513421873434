jQuery(document).ready(function($) {

    // ---- Hamburger Menu ---- //
    $('#toggle').click(function() {
       $(this).toggleClass('active');
       $('#mob_overlay').toggleClass('hamburger_open');
       $('body').toggleClass('noscroller');
    });


    // ---- Sectors Collpase accrodion ---- //
    $('.down_arrow').click(function() {
       // $(this).toggleClass('active');
       $(this).next('.secor_content').toggleClass('active_cnt');
       $(this).find($(".fas")).toggleClass('fa-chevron-up');
       // $('body').toggleClass('noscroller');
    });


    // ---- Scroll Magic ---- //
    var controller = new ScrollMagic.Controller();

    // ---- Move Vehicle on Scroll ---- //
    if($(window).width() > 768) {
      	var movevehicle = new ScrollMagic.Scene({
          triggerElement: "#get_services_snippet",
           duration: '300',
      	})

          .setTween(".truck", 1.5, {bottom: "0"})
          // .addIndicators({name: "1 (duration: 0)"});

      	controller.addScene([
      	  movevehicle
      	]);
    }

    // ---- Move Vehicle on Scroll ABOUT ---- //
    if($(window).width() > 768) {
        var moveaboutvehicle = new ScrollMagic.Scene({
          triggerElement: "#ptteries_team",
           duration: '300',
           offset: '-60'
        })

          .setTween(".truck_about", 1.5, {marginTop: "-175"})
          // .addIndicators({name: "1 (about trucker: 0)"});

        controller.addScene([
          moveaboutvehicle
        ]);
    }

    // ---- OWL carousels ---- //
    $('.inner_fleet_carousel').owlCarousel({
      loop:true,
      nav:true,
      navText: ["<i class='fas fa-chevron-left'></i>","<i class='fas fa-chevron-right'></i>"],
      items: 1
    });

    $('#home_main_carousel').owlCarousel({
      loop:true,
      nav:true,
      navText: ["<i class='fas fa-chevron-left'></i>","<i class='fas fa-chevron-right'></i>"],
      items: 1
    });

    // ---- Selectors ---- //
    $( ".sector_item_desktop" ).last().css( "border-right", "0" );


    // ====================== Modals ======================  //
    $(".opn-form-overlay").on("click", function(){

      $(".form_modal_overlay").addClass("modal_active");
      $("body").css("overflow","hidden");

    });

    $(".close_modal").on("click", function(){

      $(".form_modal_overlay").removeClass("modal_active");

      $("body").css("overflow","auto");

    });


    // ---- Isotope Blog settings ---- //
    var $container = $('#potteries_isolist');
     $container.isotope({
     itemSelector : '.item_news_wpp',
       layoutMode : 'masonry'
     });

     var $optionSets = $('#iso_filters'),
     $optionLinks = $optionSets.find('a');

     $optionLinks.click(function(){
       var $this = $(this);
         if ( $this.hasClass('selected') ) {
           return false;
         }
       var $optionSet = $this.parents('#iso_filters');
       $optionSets.find('.selected').removeClass('selected');
       $this.addClass('selected');

       var selector = $(this).attr('data-filter');
       $container.isotope({ filter: selector });

       return false;
     });

});
